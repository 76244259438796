@import "../config/colors";
@import "./var";

@mixin link-with-color($v) {
  color: c($v);
  font-weight: 400;
  text-decoration: none;

  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-image: linear-gradient(ca($v, 0.25), ca($v, 0.25));

  &:hover,
  &:focus {
    background-image: linear-gradient(ca($v, 0.5), ca($v, 0.5));
  }
}

%link {
  @include link-with-color(primary-front);
}
