/* Primary colors */
:root {
    --c-primary-front: 38, 63, 68;
    --c-primary-back: 250, 245, 228;
}

/* Greys */
$c-white: #ffffff;
$c-grey-96: #f5f5f5;
$c-grey-93: #eeeeee;
$c-grey-58: #949494;
$c-grey-40: #666666;
$c-grey-22: #3a3a3a;
$c-black: #333333;

/* Functional colors */
$c-green: #12b279;
$c-dark-green: #028859;
$c-red: #f24949;
$c-dark-red: #cf2626;
$c-yellow: #ffc440;
$c-dark-yellow: #996a00;

$c-border: $c-grey-93;
$c-text-muted: $c-grey-40;
