@function v($var) {
    @return var(--#{$var});
}

@function c($var) {
    @return RGB(var(--c-#{$var}));
}

@function ca($var, $a) {
    @return RGBA(var(--c-#{$var}), $a);
}
