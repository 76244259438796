$t-heading: "Playfair Display", serif;
$t-body: "Lato", sans-serif;

$t-heading-size: (
  1: 4rem,
  2: 3.4rem,
  3: 2rem,
  4: 1.8rem,
  5: 1.8rem,
  6: 1.8rem,
);
