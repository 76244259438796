@import "../helpers/links";

a {
  color: currentColor;
  text-decoration: none;

  &:not([class]) {
    @extend %link;
  }
}
