@import "../helpers/breakpoints";
@import "../helpers/var";
@import "../helpers/space";

body {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 100vh;
    background-color: c(primary-back);

    @include breakpoint("s") {
        align-items: flex-end;
    }
}

.main {
    width: 100vw;
    padding: space("l");

    @include breakpoint("s") {
        width: 450px;
    }
}

.box {
    padding: space("m");
    background-color: c(primary-front);
    color: c(primary-back);
    opacity : 0.7;
}

.main,
.main .box {
    > *:not(:last-child):not(.subtitle) {
        margin-bottom: space("m");
    }
}

.subtitle {
    letter-spacing : 4.29px;
    text-transform : uppercase;
}

.logo-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    margin: 0 -#{space("m")} -#{space("m")} 0;
    padding: 0;

    list-style-type: none;

    .logo {
        margin: 0 space("m") space("m") 0;
        transition: .2s ease-in-out opacity;

        a {
            color: c(primary-back);
        }

        svg {
            width: 30px;
            height: 30px;
        }

        &:hover,
        &:focus {
            opacity: .7;
        }
    }
}
